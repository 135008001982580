import { Link } from "react-router-dom";
import KeyFacts from './KeyFacts'
import { Button } from "@headlessui/react";

import {
    BriefcaseIcon,
    CalendarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CurrencyEuroIcon,
    RocketLaunchIcon,
    CalculatorIcon,
    MapPinIcon,
} from '@heroicons/react/20/solid'

export default function RoleTile(props) {
    const { isEnglish } = props

    return (
        <div id={props.index} className="py-2">
            <div className="lg:flex lg:items-center lg:justify-between lg:px-8 py-8 mt-5 bg-gray-100 rounded-lg shadow-md" style={props.toggleRoleDetails === props.toggleIndex ? { backgroundColor: 'lightgrey' } : {}}>
                <div className="min-w-0 flex-1 px-6 sm:px-0">
                    <h4 className="text-xl text-left font-bold leading-7 text-indigo-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {props.title}
                    </h4>
                    <div className="mt-0 sm:mt-2 flex flex-col sm:mt-0 sm:flex-row sm:space-x-5">
                        <div className="mt-2 flex items-center text-sm sm:text-lg text-black-500">
                            <BriefcaseIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400" />
                            {props.schedule}
                        </div>
                        <div className="mt-2 flex items-center text-sm sm:text-lg text-black-500">
                            <MapPinIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400" />
                            {props.location}
                        </div>
                        <div className="mt-2 flex items-center text-sm sm:text-lg text-black-500">
                            <CurrencyEuroIcon aria-hidden="true" className="mr-1.5 h-5 :w-5 flex-shrink-0 text-black-400" />
                            {props.salaryRange}
                        </div>
                        <div className="mt-2 flex items-center text-sm sm:text-lg text-black-500">
                            <CalendarIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400" />
                            {props.timeline}
                        </div>
                    </div>
                </div>
                <div className="mt-6 px-6 sm:px-0 sm:basis-auto flex-col-reverse sm:flex-row flex lg:ml-0">
                    <div className="sm:block w-full sm:w-auto h-full">
                        <Button
                            onClick={() => {
                                props.setToggleRoleDetails(props.toggleRoleDetails === props.toggleIndex ? null : props.toggleIndex)
                                if (props.toggleRoleDetails !== props.toggleIndex) {
                                    setTimeout(() => {
                                        props.anchorScroll(`#${props.index}`)
                                    }, 200)
                                }
                            }}
                            type="button"
                            className="justify-center flex w-full items-center rounded-md bg-white px-3.5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            {props.toggleRoleDetails === props.toggleIndex ? <ChevronUpIcon aria-hidden="true" className="-ml-0.5 h-6 w-6 text-gray-400" /> : <ChevronDownIcon aria-hidden="true" className="-ml-0.5 h-6 w-6 text-gray-400" />}
                            {isEnglish ? "View Details" : "Voir les détails"}
                        </Button>
                    </div>

                    <div className="flex-1 sm:ml-3">
                        {props.origin === 'home' ?
                            <Link
                                onClick={() => props.setCurrentScreen('/careers')}
                                className="mb-2 sm:mb-3 inline-flex w-full justify-center items-center rounded-md bg-gray-900 px-3.5 py-2.5 text-sm sm:text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                to={'/careers'}>
                                <CalculatorIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                                {isEnglish ? "Calculate your revenues" : "Calculez vos revenus"}
                            </Link>
                            :
                            <Button
                                onClick={() => props.anchorScroll('#contact-form')}
                                className="mb-2 sm:mb-3 inline-flex w-full justify-center items-center rounded-md bg-gray-900 px-3.5 py-2.5 text-sm sm:text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                style={{ marginBottom: '5px' }}
                            >
                                <RocketLaunchIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
                                {isEnglish ? "Apply" : 'Postuler'}
                            </Button>
                        }
                    </div>
                </div>

            </div>
            {props.toggleRoleDetails === props.toggleIndex &&
                <KeyFacts
                    isEnglish={isEnglish}
                    facts={props.facts}
                    img={props.factsImg}
                />
            }
        </div>
    )
}
