import './DarkPanel.css'

import gp from '../assets/gp.jpg'
import dentist from '../assets/camera.JPG'
import cardio from '../assets/cardio.jpg'
import chaise from '../assets/chaise.jpg'
import bloodTest from '../assets/blood-test.jpg'

export default function DarkPanel(props) {
    const { isEnglish } = props

    return (
        <div className="mx-auto">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-12 sm:pt-0 shadow-2xl rounded-3xl sm:px-16 lg:flex lg:gap-x-20">
                <svg
                    viewBox="0 0 1024 1024"
                    aria-hidden="true"
                    className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                >
                    <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                            <stop stopColor="#7775D6" />
                            <stop offset={1} stopColor="#E935C1" />
                        </radialGradient>
                    </defs>
                </svg>
                <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-28 lg:text-left">
                    <h2 className="text-2xl sm:text-3xl tracking-tight text-white">
                        {isEnglish ? 'Boost your career' : "Boostez votre carrière"}
                    </h2>
                    <p className="mt-6 text-md sm:text-lg leading-7 text-gray-300">
                        {isEnglish ?
                            "We are currently recruiting dentists, general practitioners, cardiologists, ORLs and other specialist doctors for our clinics at the heart of France."
                            :
                            "Nous recrutons actuellement des dentistes, médecins généralistes, cardiologues, ORLs et autres spécialistes pour nos cabinets en coeur de France."
                        }
                        <br />
                        {window.innerWidth > 1024 &&
                            <>
                                {!isEnglish && 'Contactez nous '}
                                <span className='font-bold text-lg'>{isEnglish ? 'Inquire today' : 'dés maintenant'}</span>
                                {isEnglish ?
                                    " and take the first step towards a rewarding career in France."
                                    :
                                    " et faites le premier pas vers une nouvelle carrière en France."
                                }
                            </>
                        }
                    </p>
                    <div className="mt-6 flex items-center justify-center gap-x-6 lg:justify-start">
                        <div
                            onClick={() => props.anchorScroll('#contact-form')}
                            className="rounded-md cursor-pointer bg-white px-3.5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            {isEnglish ? 'Talk to us' : 'Parlez nous'}
                        </div>
                        <a href='/about' className="text-sm sm:text-md cursor-pointer font-semibold text-white">
                            {isEnglish ? "Who are we " : 'Qui sommes nous '}
                            <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <div className="relative -translate-x-14 sm:translate-x-0 mt-16 h-80 sm:h-80 lg:mt-8">
                    <div width={1824}
                        height={1080}
                        className="gridContainer absolute left-0 top-0 sm:w-[57rem] w-[30rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10">
                        <div className="tile">
                            <img src={dentist} alt="" />
                        </div>
                        <div className="tile">
                            <img src={gp} alt="" />
                        </div>
                        <div className="tile">
                            <img src={chaise} alt="" />
                        </div>
                        <div className="tile">
                            <img src={cardio} alt="" />
                        </div>
                        <div className="tile">
                            <img src={bloodTest} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
