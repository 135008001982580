import { CalculatorIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import team from '../assets/team.jpg'

export default function JobsHero(props) {
    const { isEnglish, anchorScroll } = props

    return (
        <div className="overflow-x-hidden">
            <div className='jobHeroPattern overflow-x-hidden heroPatternStarsH absolute top-0 left-0 translate-x-0 translate-y-20 sm:translate-x-14 sm:translate-y-32 -z-10' />
            {window.innerWidth > 1024 && <div className='absolute top-1/5 left-2/3 rounded-full -z-10 opacity-50' style={{ backgroundColor: '#1A237E', width: '900px', height: '900px' }} />}
            <div className="relative isolate">
                <div className="jobHero mx-auto max-w-7xl">
                    <div className="mx-4 sm:mx-8 flex max-w-2xl flex-col gap-8 sm:gap-16 px-6 py-8 sm:p-12 shadow-xl rounded-3xl ring-1 ring-white/10 rounded-3xl shadow-2xl lg:max-w-none lg:flex-row lg:items-center" style={{ backgroundColor: '#231a2e' }}>
                        <img
                            alt=""
                            src={team}
                            className="h-50 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                        />
                        <div className="w-full flex-auto">
                            <h4 className="text-xl sm:text-2xl font-bold tracking-tight text-white">{isEnglish ? 'Join our team' : 'Rejoignez notre équipe'}</h4>
                            <p className="mt-0 sm:mt-2 text-md sm:text-lg leading-6 text-gray-300">
                                {isEnglish ? 'Start your new career as a doctor in France' : 'Commencez votre nouvelle carrière en France'}
                            </p>
                            <ul
                                role="list"
                                className="mt-6 grid grid-cols-1 gap-x-8 gap-y-1 sm:gap-y-3 text-base leading-6  text-white sm:grid-cols-2"
                            >
                                {props.benefits.map((benefit) => (
                                    <li key={benefit} className="flex items-center gap-x-3 text-sm sm:text-base">
                                        <CheckCircleIcon aria-hidden="true" className="h-8 w-6 sm:h-7 sm:w-5 flex-none" />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-6 sm:mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <button className="flex cursor-pointer rounded-md bg-white px-3.5 py-2.5 text-sm sm:text-md font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                    <div onClick={() => anchorScroll('#calculator')} >
                                        {isEnglish ? 'Calculate your salary' : 'Calculez votre salaire'}
                                    </div>
                                    <CalculatorIcon className='w-6 h-6 sm:w-5 sm:h-5 ml-2' />

                                </button>
                                <div onClick={() => anchorScroll('#jobs')} className="text-sm sm:text-md cursor-pointer font-semibold leading-6 text-white">
                                    {isEnglish ? 'See our job postings ' : "Voir nos offres d'emploi "}<span aria-hidden="true">&rarr;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
