import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import 'animate.css';
import './Navbar.css'
import whatsapp from '../../assets/whatsapp.svg'
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon, CalendarDaysIcon, Bars3Icon, HomeIcon, InformationCircleIcon, PhoneIcon, BriefcaseIcon } from '@heroicons/react/20/solid'
import britishFlag from '../../assets/britishFlag.png'
import frenchFlag from '../../assets/frenchFlag.png'
import logo from '../../assets/logo.svg'
import logoIcon from '../../assets/logoIcon.svg'

const flags = { EN: britishFlag, FR: frenchFlag }

function Navbar(props) {
    const navigate = useNavigate();
    const [burgerOpen, setBurgerOpen] = useState(false)
    const { isEnglish } = props

    useEffect(() => {
        if (burgerOpen) {
            const rotateIn = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('rotateIn');
                    }
                });
            });

            //Effects
            rotateIn.observe(document.querySelector('.burgerMenu'))
        }
    }, [burgerOpen])

    return (
        <div className='navbar'>
            {window.innerWidth <= 1024 &&
                <div className='navbarBurgerContainer'>
                    <Menu as="div" className="data-[active]:flipInX relative inline-block text-right mr-2">
                        <MenuButton className='text-gray-900 data-[active]:text-indigo-600 flex px-2 sm:px-4 pt-1 pb-1 items-center text-gray-900 hover:text-gray-600 focus:outline-none' onClick={() => setBurgerOpen(!burgerOpen)}>
                            <Bars3Icon aria-hidden="true" className={`${burgerOpen && 'burgerMenu'} h-9 w-9`} />
                        </MenuButton>

                        <MenuItems
                            anchor="bottom end"
                            transition
                            className="z-10 divide-y divide-gray-300 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div>
                                <MenuItem>
                                    <div
                                        onClick={() => {
                                            setBurgerOpen(false)
                                            navigate('/home')
                                        }}
                                        className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-3 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                        <HomeIcon aria-hidden="true" className="h-4 w-4 mr-2 text-indigo-600" />
                                        <p className='mt-1'>{isEnglish ? 'HOME' : 'ACCUEIL'}</p>
                                    </div>
                                </MenuItem>
                            </div>
                            <div>
                                <MenuItem>
                                    <div
                                        onClick={() => {
                                            setBurgerOpen(false)
                                            navigate('/careers')
                                        }}
                                        className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-3 text-sm text-gray-700 w-full data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                        <BriefcaseIcon aria-hidden="true" className="h-4 w-4 mr-2 text-indigo-600" />
                                        <p className='mt-1'>{isEnglish ? 'CAREERS' : 'CARRIERES'}</p>
                                    </div>
                                </MenuItem>
                            </div>
                            <div>
                                <MenuItem>
                                    <div
                                        onClick={() => {
                                            setBurgerOpen(false)
                                            navigate('/about')
                                        }}
                                        className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-3 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                        <InformationCircleIcon aria-hidden="true" className="h-4 w-4 mr-2 text-indigo-600" />
                                        <p className='mt-1'>{isEnglish ? 'ABOUT' : 'A PROPOS'}</p>
                                    </div>
                                </MenuItem>
                            </div>
                            <div>
                                <MenuItem>
                                    <div
                                        onClick={() => {
                                            setBurgerOpen(false)
                                            navigate('/contact')
                                        }}
                                        className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-3 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                        <PhoneIcon aria-hidden="true" className="h-4 w-4 mr-2 text-indigo-600" />
                                        <p className='mt-1'>CONTACTS</p>
                                    </div>
                                </MenuItem>
                            </div>
                            <div>
                                <MenuItem>
                                    <div style={{ display: 'flex', margin: '18px', justifyContent: 'space-between' }}>
                                        <p className='mt-2 text-sm'>{isEnglish ? 'Get in touch:' : 'Nous parler:'}</p>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <a href="https://calendly.com/neomed/intro-call" target="_blank" rel="noreferrer">
                                                <button className='ml-4 sm:mr-2 flex items-center inline-block rounded-md bg-black p-1 sm:p-4 text-gray-900 text-white text-sm font-semibold shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                                    {window.innerWidth > 1024 &&
                                                        (isEnglish ?
                                                            'Book a call'
                                                            : "Prendre RDV")
                                                    }
                                                    <CalendarDaysIcon className={`w-6 h-6 ${window.innerWidth > 1024 && 'ml-2'}`} />
                                                </button>
                                            </a>
                                            <a href="https://wa.me/33652547516" target="_blank" rel="noreferrer">
                                                <button className='ml-1 sm:mr-5 flex items-center inline-block rounded-md bg-gray-100 p-0 sm:p-4 text-sm font-semibold shadow-lg hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50'>
                                                    {window.innerWidth > 1024 &&
                                                        (isEnglish ? 'Chat with us'
                                                            :
                                                            "Whatsapp")
                                                    }
                                                    <img src={whatsapp} className={`block ${window.innerWidth > 1024 ? 'ml-2 h-6 w-6' : 'h-8 w-8'}`} alt="whatsapp" />
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </Menu>
                </div>
            }
            <div className='navbarLogoContainer'>
                <Link onClick={() => props.setCurrentScreen('/home')} className='link' to={'/home'}><img loading="lazy" className='logo' src={logo} alt='' /></Link>
            </div>
            {window.innerWidth > 1024 &&
                <>
                    <div className='navbarNavContainer flex flex-row-reverse sm:flex-row'>
                        {props.currentScreen === '/' || props.currentScreen === '/home' ?
                            <div className='navTitle'>
                                <Link onClick={() => props.setCurrentScreen('/home')} className='link' style={{ marginBottom: '5px' }} to={'/home'}>{isEnglish ? 'HOME' : 'ACCUEIL'}</Link>
                                <span className='underline' />
                            </div>
                            :
                            <div className="hover-underline-animation">
                                <Link onClick={() => props.setCurrentScreen('/home')} className='link' to={'/home'}>{isEnglish ? 'HOME' : 'ACCUEIL'}</Link>
                            </div>
                        }
                        {props.currentScreen === '/careers' ?
                            <div className='navTitle'>
                                <Link onClick={() => props.setCurrentScreen('/careers')} className='link' style={{ marginBottom: '5px' }} to={'/careers'}>{isEnglish ? 'CAREERS' : 'CARRIERES'}</Link>
                                <span className='underline' />
                            </div>
                            :
                            <div className="hover-underline-animation">
                                <Link onClick={() => props.setCurrentScreen('/careers')} className='link' to={'/careers'}>{isEnglish ? 'CAREERS' : 'CARRIERES'}</Link>
                            </div>
                        }
                        {
                            props.currentScreen === '/about' ?
                                <div className='navTitle'>
                                    <Link onClick={() => props.setCurrentScreen('/about')} className='link' style={{ marginBottom: '5px' }} to={'/about'}>{isEnglish ? 'ABOUT' : 'A PROPOS'}</Link>
                                    <span className='underline' />
                                </div>
                                :
                                <div className="hover-underline-animation">
                                    <Link onClick={() => props.setCurrentScreen('/about')} className='link' to={'/about'}>{isEnglish ? 'ABOUT' : 'A PROPOS'}</Link>
                                </div>
                        }
                        {
                            props.currentScreen === '/contact' ?
                                <div className='navTitle'>
                                    <Link onClick={() => props.setCurrentScreen('/contact')} className='link' style={{ marginBottom: '5px' }} to={'/contact'}>CONTACT</Link>
                                    <span className='underline' />
                                </div>
                                :
                                <div className="hover-underline-animation">
                                    <Link onClick={() => props.setCurrentScreen('/contact')} className='link' to={'/contact'}>CONTACT</Link>
                                </div>
                        }
                    </div>
                    <div className='navbarButtonsContainer'>
                        <a href="https://calendly.com/neomed/intro-call" target="_blank" rel="noreferrer">
                            <button className='ml-4 sm:mr-2 flex items-center inline-block rounded-md bg-black p-1 sm:p-4 text-gray-900 text-white text-sm font-semibold shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                {window.innerWidth > 1024 &&
                                    (isEnglish ?
                                        'Book a call'
                                        : "Prendre RDV")
                                }
                                <CalendarDaysIcon className={`w-6 h-6 ${window.innerWidth > 1024 && 'ml-2'}`} />
                            </button>
                        </a>
                        <a href="https://wa.me/33652547516" target="_blank" rel="noreferrer">
                            <button className='ml-1 sm:mr-5 flex items-center inline-block rounded-md bg-gray-100 p-0 sm:p-4 text-sm font-semibold shadow-lg hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50'>
                                {window.innerWidth > 1024 &&
                                    (isEnglish ? 'Chat with us'
                                        :
                                        "Whatsapp")
                                }
                                <img src={whatsapp} className={`block ${window.innerWidth > 1024 ? 'ml-2 h-6 w-6' : 'h-8 w-8'}`} alt="whatsapp" />
                            </button>
                        </a>
                    </div>
                </>
            }
            <Menu as="div" className="fixed bottom-4 right-5 z-20 inline-block text-left">
                <div>
                    <MenuButton className="flex px-3 sm:px-5 pt-3 pb-3 items-center shadow-md rounded-lg bg-gray-100 opacity-80 hover:bg-gray-200 text-gray-900 hover:text-gray-600 focus:outline-none">
                        <img src={flags[isEnglish ? 'EN' : 'FR']} alt={isEnglish ? 'EN' : 'FR'} className='w-5 h-3 mr-1' />
                        {window.innerWidth > 1024 && <span className='text-sm font-semibold mr-2 mt-1'>{isEnglish ? 'EN' : 'FR'}</span>}

                        <EllipsisVerticalIcon aria-hidden="true" className="h-4 w-4 text-gray-900" />
                    </MenuButton>
                </div>

                <MenuItems
                    transition
                    className={`absolute -top-24 right-0 z-10 mt-2 pr-5 pl-1 sm:pr-0 sm:pl-0 sm:w-56 bg-white origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
                >
                    <div className="py-1 z-10">
                        <MenuItem>
                            <div
                                onClick={() => {
                                    localStorage.setItem("language", 'EN');
                                    props.setIsEnglish(true)
                                }}
                                className="block flex items-center cursor-pointer px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                <img src={britishFlag} alt={isEnglish ? 'EN' : 'FR'} className='w-5 h-3 mr-2 -translate-y-0.5' />
                                English
                            </div>
                        </MenuItem>
                        <MenuItem>
                            <div
                                onClick={() => {
                                    localStorage.setItem("language", 'FR');
                                    props.setIsEnglish(false)
                                }}
                                className="block flex items-center cursor-pointer px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                <img src={frenchFlag} alt={isEnglish ? 'EN' : 'FR'} className='w-5 h-3 mr-2 -translate-y-0.5' />
                                Français
                            </div>
                        </MenuItem>
                    </div>
                </MenuItems>
            </Menu>
        </div>
    )
}

export default Navbar